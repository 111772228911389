import il8n from '@/assets/language/index.js';
const { t } = il8n.global;
const mainRules = {
    tenantId: [{ required: true, message: t('inputTit.xuanze'), trigger: 'blur' }],
    appid: [{ required: true, message: t('inputTit.xuanze'), trigger: 'blur' }],
    mobile: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    email: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    telegramGroupId: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }]
}
export default{
    mainRules
}