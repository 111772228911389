import il8n from '@/assets/language/index.js';
const { t } = il8n.global;
export const tableOption1 = {
        border: true,
        stripe: true,
        menuAlign: 'center',
        searchMenuSpan: 6,
        height: 'auto',
        align: 'center',
        viewBtn: false,
        columnBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        refreshBtn: false,
        menuWidth: 120,
        menu: false,
        column: [ {
            width: 130,
            label: t('notice.text1'),
            prop: 'tenantName',
        }, {
            width: 100,
            label: t('notice.text2'),
            prop: 'appName',
        },{
            width: 130,
            label: t('notice.text9'),
            prop: 'mobile',
        },{
            width: 130,
            label: t('notice.text10'),
            prop: 'email',
        },  {
            width: 130,
            label: 'TelegramID',
            addDisplay: false,
            prop: 'telegramGroupId',
            disabled: true,
            span:24
        }, {
            width: 160,
            label: t('notice.text4'),
            prop: 'isWaterlineNotice',
        },{
            width: 120,
            label: t('notice.text5'),
            prop: 'isSmsNotice',
        },{
            width: 160,
            label: t('notice.text6'),
            prop: 'isEmailNotice',
        },{
            width: 160,
            label: t('notice.text7'),
            prop: 'isTelegramNotice',
        },{
            width: 160,
            label: t('notice.text11'),
            prop: 'language',
            fixed:'right',
        },{
            width: 110,
            label: t('notice.text8'),
            prop:'updateUser',
            editDisplay: false,
            addDisplay: false,
        },{
            width: 120,
            label: t("button.operation"),
            prop: 'caozuo',
            placeholder: t("inputTit.shuru"),
            fixed:'right',
          }]
    }