import il8n from '@/assets/language/index.js';
const { t } = il8n.global;

export const notice = [{
    type: 'select',
    name: t('notice.text1'),
    prop: 'tenantId',
    isPostApi: 'shopList',
    isPlatform: 1,//平台显示，商户不显示
    props:{
        value:'id',
        label:'name'
    },
},{
    type: 'select',
    name: t('notice.text2'),
    prop: 'appId',
    isPostApi: 'getUserappinfoList',
    postURl: '',
    postData: [],   
    props:{
        value:'appId',
        label:'appName'
    }
},{
    type: 'select',
    name: t('notice.text3'),
    prop: 'userId',
    isPostApi: 'adminUser',
    postURl: '',
    postData: [],   
    props:{
        label: 'username',
        value: 'userId'
    }
},]