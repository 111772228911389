<template>
    <div>
        <fromSearch :object_='seriesList_' @searchFun='getList' />
        <avue-crud :option="tableOption" :data="page.list" :page="page" :table-loading="listLoading"
            @size-change="sizeChange"
            @current-change="currentChange">
            <template #menu-left>
                <el-button v-if="roleManager_btn_add" class="filter-item"
                    type="primary"   @click="openView(0)">{{$t('button.add')}} </el-button>
            </template>
            <template #isWaterlineNotice ="scope">
                <el-switch v-model="scope.row.isWaterlineNotice" :inactive-value='parseInt(valueArray[0].value)' :active-value='parseInt(valueArray[1].value)' @change='changeStatus(scope.row,"isWaterlineNotice")'/>
            </template> 
            <template #isSmsNotice ="scope">
                <el-switch v-model="scope.row.isSmsNotice" :inactive-value='parseInt(valueArray[0].value)' :active-value='parseInt(valueArray[1].value)' @change='changeStatus(scope.row,"isSmsNotice")'/>
            </template> 
            <template #isEmailNotice ="scope">
                <el-switch v-model="scope.row.isEmailNotice" :inactive-value='parseInt(valueArray[0].value)' :active-value='parseInt(valueArray[1].value)' @change='changeStatus(scope.row,"isEmailNotice")'/>
            </template> 
            <template #isTelegramNotice ="scope">
                <el-switch v-model="scope.row.isTelegramNotice" :inactive-value='parseInt(valueArray[0].value)' :active-value='parseInt(valueArray[1].value)' @change='changeStatus(scope.row,"isTelegramNotice")'/>
            </template> 
            <template #language ="scope">
                <el-select v-model="scope.row.language" placeholder="Select" @change="getLang(scope.row)">
                    <el-option v-for="item in languagesOfObject" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </template> 
            <template #caozuo="scope">
                <el-button v-if="roleManager_btn_edit" text type="primary"
                    size="small"    @click="openView(1,scope.row)">{{$t('button.update')}} </el-button>
            </template>
        </avue-crud>

         <el-dialog v-model="innerVisible1" width="500" :title="textArray[pageStyle]" >
            <bindView @successFun='successFun' ref='addView_' @closeFun='innerVisible1 = false'/>
        </el-dialog>
    </div>
</template>
<script setup>
    import Api_ from '@/api/index'
    import { ref,getCurrentInstance,nextTick } from 'vue'
    import { tableOption1 } from "@/const/crud/platform/notice";
    import { notice } from "@/const/from/platform/notice";
    import { ElNotification } from 'element-plus'
    import fromSearch from '@/components/fromSearch.vue'
    import bindView from './bind.vue'
    // import { languagesOfObject } from '@/config/language.js'
    import { getDataStyle } from '@/utils/dataBind/parmsView' 

    const { $t } = getCurrentInstance().proxy;
    import mixins from '@/views/mixins/page'
    let pageObj = mixins(['getNoticeList'])
    const {page,listLoading,sizeChange,currentChange,getList} = pageObj

    const tableOption = ref({}); tableOption.value = tableOption1
    const seriesList_ = ref(notice);
    const languagesOfObject = ref([]);getDataStyle('language_type',(e)=>{languagesOfObject.value = e})
    const valueArray = ref([])
    getDataStyle('notice_style',(e)=>{valueArray.value = e})
    
    const addView_ = ref(null)
    const roleManager_btn_add = ref(true)
    const roleManager_btn_edit = ref(true)

    const textArray = ['新增','修改']
    const pageStyle = ref(0)
    const innerVisible1 = ref(false)

    const getLang = (e)=>{//获得语言
        listLoading.value = true
        Api_.updateNoticeObj({configId:e.configId,language:e.language}).then(res=>{
            listLoading.value = false
            if(res.code === 0){
                ElNotification({
                    message: $t('alert.succUpdate'),
                    type: 'success',
                })
                getList()
            }else{
                ElNotification({
                    message: res.msg,
                    type: 'error',
                })
            }
        })
    }
    const openView = (num,parm)=>{
        innerVisible1.value = true
        pageStyle.value = num
        nextTick(()=>{
            addView_.value.init_(num,parm)
        })
    }
    const changeStatus = (e,str)=>{//
        let obj_ = {configId:e.configId}
        obj_[str] = e[str]
        if(obj_.configId){
            listLoading.value = true
            Api_.updateNoticeObj(obj_).then(res=>{
                listLoading.value = false
                if(res.code === 0){
                    ElNotification({
                        message: e[str] === 1?$t('alert.succOpen'):$t('alert.succOff'),
                        type: 'success',
                    })
                    getList()
                }else{
                    ElNotification({
                        message: res.msg,
                        type: 'error',
                    })
                }
            })
        }
    }
    const successFun = ()=>{
        innerVisible1.value = false
        getList(1)
    }
    getList(1)
</script>