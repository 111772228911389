<template>
   <el-form-item :label="$t('currencySet.name1')" prop="tenantId" v-if="isPlatform">
        <el-select v-model="fromValue.tenantId" :placeholder="$t('inputTit.xuanze')" v-if="style_ !== 1"
            @change="getLists('tenantId')" :teleported='false'>
            <el-option :label="item.name" :value="item.id" v-for='item in tenantArray' :key = 'item.id'/>
        </el-select>
        <el-input v-model="fromValue1.tenantName" disabled v-else/>
    </el-form-item>
    <el-form-item :label="$t('currencySet.name2')" prop="appId">
        <el-select v-model="fromValue.appId" :placeholder="$t('inputTit.xuanze')" v-if="style_ !== 1"
            @change="getLists('appId')" :teleported='false'>
            <el-option :label="item.appName" :value="item.appId" v-for='item in appArray' :key = 'item.appId'  />
        </el-select>
        <el-input v-model="fromValue1.appName" disabled v-else />
    </el-form-item>
</template>
<script setup>
    import { reactive,defineEmits,ref,defineProps,toRefs,defineExpose } from 'vue'
    import { userStore } from '@/store/user'
    import apiList from '@/const/apiList'

    const props = defineProps({
        style_: String
    })
    const fromValue = reactive({
        tenantId:'',
        appId: ''
    })
    const fromValue1 = reactive({
        tenantName:'',
        appName: ''
    })
    const { style_ } = toRefs(props);
    const tenantArray = ref([])
    const appArray = ref([])
    const emit = defineEmits(['getTenantIdAndAppid'])

    const isPlatform = ref(null);isPlatform.value = userStore().getIsPlatform
    fromValue.tenantId = userStore().getUserInfo[0].tenantId
    if(isPlatform.value){//是平台数据，获得
        apiList.getTenantList((data)=>{ tenantArray.value = data })
    }
    apiList.getAppList((data)=>{ appArray.value = data },fromValue.tenantId)

    const getLists = (str)=>{
        if(str === 'tenantId'){
            fromValue.appId = ''
            apiList.getAppList((data)=>{ appArray.value = data },fromValue.tenantId)
        }else{
            emit('getTenantIdAndAppid',fromValue)
        }
    }
    const setData = (e,e1)=>{
        fromValue1.tenantName = e
        fromValue1.appName = e1
    }
    defineExpose({setData})
</script>