<template>
    <div class="init">
        <el-form :model="fromValue" label-width="auto"  ref='form_' class="fromClass"
            :rules='rules' label-position="top">
            <tanAndAppid @getTenantIdAndAppid='getTenantIdAndAppid' :style_='style_' ref='tanAndAppid_'/>

            <el-form-item :label="$t('notice.text10')" prop="email">
                <el-input v-model="fromValue.email" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>
            <el-form-item :label="$t('notice.text9')" prop="mobile">
                <el-input v-model="fromValue.mobile" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>

            <el-form-item label="Telegram ID" prop="telegramGroupId">
                <el-input v-model="fromValue.telegramGroupId" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>
            <el-row class="row-bg" justify="end">
                <el-button    @click="emit('closeFun')">{{$t('button.closeQ')}} </el-button>
                <el-button type="primary"   @click="completeFun(form_)">{{$t('button.tijiao')}}</el-button>
            </el-row>
            <!-- <el-form-item >
                <el-button    @click="emit('closeFun')">关闭 </el-button>
                <el-button type="primary"   @click="completeFun(form_)">提交</el-button>
            </el-form-item> -->
        </el-form>
    </div>
</template>
<script setup>
    import { ref,defineExpose,reactive,defineEmits,getCurrentInstance } from 'vue'
    import Api_ from '@/api/index'
    import { ElNotification } from 'element-plus'
    import addRules from './addRules'
    import tanAndAppid from '@/views/components/tenAndAppid.vue'
    const { $t } = getCurrentInstance().proxy;
    
    const fromValue = reactive({ 
        configId: '',tenantId: '',appid:'',
        mobile: '',email: '',telegramGroupId:'',
    })
    const tanAndAppid_ = ref(null)

    const getTenantIdAndAppid = (e)=>{//获得公共组件中的app和商户
        if(e){
            fromValue.tenantId = e.tenantId
            fromValue.appid = e.appId
        }
    }

    const form_ = ref(null)
    const rules = ref(null);
    rules.value = addRules.mainRules
    const style_ = ref(0)

    const emit = defineEmits(['successFun','closeFun'])
    
    const completeFun = (form_)=>{
        if (!form_) return
        form_.validate((e) => {
            if(e){
                if(style_.value === 0){
                    let obj_ = JSON.parse(JSON.stringify(fromValue))
                    delete obj_.configId
                    Api_.addNoticeObj(obj_).then(res=>{
                        if(res.code === 0){
                            emit('successFun')
                            ElNotification({
                                message: $t('alert.succAdd'),
                                type: 'success',
                            })
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }else{
                    Api_.updateNoticeObj(fromValue).then(res=>{
                        if(res.code === 0){
                            emit('successFun')
                            ElNotification({
                                message: $t('alert.succUpdate'),
                                type: 'success',
                            })
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }
            }
        })
    }
    const init_ = (e,obj)=>{//初始化数据
        style_.value = e;
        if(obj){
            fromValue.configId = obj.configId;
            fromValue.tenantId = obj.tenantId;
            fromValue.appid = obj.appid;
            fromValue.mobile = obj.mobile;
            fromValue.email = obj.email;
            fromValue.telegramGroupId = obj.telegramGroupId;
            tanAndAppid_.value.setData(obj.tenantName,obj.appName)
        }else{
            for(let index in fromValue){
                fromValue[index] = ''
            }
        }
    }
    defineExpose({init_})
</script>